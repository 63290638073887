* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  color: #fff;
  font-family: Helvetica;
}

.bg-pri {
  background-color: red !important;
  color: #fff;
}

.text-pri {
  color: #fff !important;
}

.btn-outline-pri {
  border: 1px solid #fff;
}

.mmt {
  margin-top: 5rem;
}

@media only screen and (max-width: 600px) {
  .rmt-1 {
    margin-top: 1rem !important;
  }
  .rmt-2 {
    margin-top: 2rem !important;
  }
  .rmt-3 {
    margin-top: 3rem !important;
  }
}
