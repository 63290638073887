.home {
  background-color: #81aaec;
}

.navb {
  background-image: url("../../assets/img/Ice2.png");
  background-repeat: no-repeat;
}

.cusbtn {
  text-decoration: none !important;
  background-color: #205bac;
  color: #fff;
  font-size: 1.4rem;
  padding: 0.5rem 1.5rem;
  border: 3px solid #fff;
  font-weight: bold;
  border-radius: 20px;
  letter-spacing: 3px;
}

.cusbtn:hover {
  background-color: #fff;
  color: #000;
}

.showcase {
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
}

.title {
  font-weight: bold;
}
.subtitle {
  font-weight: bold;
  font-size: 1.3rem;
}

.timer {
  padding-bottom: 5rem;
  // background-color: #0a4991;
}

.team {
  background-color: #0a4991;

  .teaming {
    border-radius: 16px;
  }
}

.roadmap {
  padding-top: 5rem;
  background-color: #0a4991;
}

.faq {
  .ftitle {
    font-size: 3rem;
    font-weight: bold;
  }
  .ic5 {
    padding-top: 5rem;
    background-color: #0a4991;
  }
  .about-content {
    background-color: #81aaec;
  }
  .faq-content {
    padding-top: 4rem;
    padding-bottom: 10rem;
    .bt {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}
.si {
  height: 40px;
}

.footer {
  background-color: #122b4f;
  // padding: 3rem 0 0.1rem 0;
}

.mmt {
  margin-top: 4rem;
}

@media only screen and (max-width: 600px) {
  .showcase {
    height: auto;
  }
}
